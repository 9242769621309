import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'protractor';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  config: Config;

  constructor(private http: HttpClient) {}

  loadConfig() {
    const jsonFile = './assets/config.json';
    // return this.http.get<Config>(jsonFile).toPromise().then(config => {
    //     this.config = config;
    //   }).catch((response: any) => {
    //     console.log(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
    //   });
    return new Promise<void>((resolve, reject) => {
        this.http.get(jsonFile).toPromise().then((response : Config) => {
          this.config = <Config>response;
          resolve();
        }).catch((response: any) => {
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }
}
  