import { Injectable } from '@angular/core';

@Injectable()
export class FundConstants {

    changeCusipReasonID : string;
    mergerReasonID: string;
    pendingFundStatusID: string;
    importTypeDiv: number;
    importTypeNAVnMIL: number;
    importTypeNASDAC: number;
    groupType: { NAV: number, MIL: number, DIV: number, NASDAC: number};
    fileFormats: {csv: string, txt: string, exl: string};
    importFundNumberId: number;
    importNavColmunId: number;
    importAccuralColumnId: number;
    import_isNumeric: string;

    constructor() {
        this.changeCusipReasonID = '4';
        this.mergerReasonID = '1';
        this.pendingFundStatusID = '2';
        this.importTypeDiv = 2;
        this.importTypeNAVnMIL = 1;
        this.importTypeNASDAC = 3;
        this.groupType = { NAV: 1, MIL: 2, DIV: 3, NASDAC: 4};
        this.fileFormats = {csv: 'CSV', txt: 'TXT', exl: 'EXL'};
        this.importFundNumberId = 4;
        this.importNavColmunId = 10;
        this.importAccuralColumnId = 11;
        this.import_isNumeric = '7';
    }
}