import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './shared.service';
import { RoleObj } from '../user-dashboard/user.class';
import { ConfigService } from './ConfigService';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  menuList = [];
  url = '';//environment.getBaseUrl();
  roles: Array<RoleObj>;

  constructor(private https: HttpClient, private shareds: SharedService, private configService: ConfigService) { 
    //console.log('config', configService.config);
    this.url = configService.config.apiUrl;
    this.menuList = this.shareds.menuList;
  }

  getHeaderWithToken(): any {
    return {
      headers: new HttpHeaders({  /// returns headers with token //
        'Content-Type': 'application/json',
        Authorization:  localStorage.getItem('authToken') ? 'Bearer ' + localStorage.getItem('authToken') : ''
      })
    };
  }

  getHeader() {
    return {  /// returns only headers
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }

  // getLinkedPendingFunds(data: any) {
  //   return this.https.post(`${this.url}CustomerPendingFunds/GetLinkedCustomerPendingFundsList`, JSON.stringify(data),
  //     this.getHeaderWithToken());
  // }

  changeUserPassword(data: any) {
    return this.https.post(`${this.url}user/ChangePassword`,
      JSON.stringify(data),
      this.getHeaderWithToken());
  }

  updateUserProfile(data: any) {
    return this.https.post(`${this.url}user/UpdateUser`,
      JSON.stringify(data),
      this.getHeaderWithToken());
  }

  isFirstLogin() {
    return this.https.get(`${this.url}user/IsUserFirstLogin`,
      this.getHeaderWithToken());
  }

  addUser(data: any) {
    return this.https.post(`${this.url}user/AddUser`,
      JSON.stringify(data),
      this.getHeaderWithToken());
  }

  getaAllUserList(data: any) {
    return this.https.post(`${this.url}user/GetAllUsers`,
    JSON.stringify(data),
    this.getHeaderWithToken());
  }

  getUserTypes() {
    return this.https.get(`${this.url}user/GetAllUserTypes`,
    this.getHeaderWithToken());
  }

  getUserLoginType() {
    return this.https.get(`${this.url}user/GetAllLoginTypes`,
    this.getHeaderWithToken());
  }


  getAllPermissionList() {
    return this.https.get(`${this.url}user/GetAllUserPermissionList`,
    this.getHeaderWithToken());
  }

  getAllPermissions() {
    return this.https.get(`${this.url}user/GetAllUserPermissions`,
    this.getHeaderWithToken());
  }

  getAllPermissionRoleMapping() {
    return this.https.get(`${this.url}user/GetAllPermissionRoleMapping`,
    this.getHeaderWithToken());
  }

  getUserPermissionsByUserId() {
    return this.https.get(`${this.url}user/GetUserPermissionsByUserId`,
    this.getHeaderWithToken());
  }

  getAllRoles() { // GetAllUserRoles
    return this.https.get(`${this.url}user/GetAllUserRoles`,
    this.getHeaderWithToken());
  }


  updateUserRole(data: any, type: string) {
    if (type === 'edit') {
      return this.https.post(`${this.url}user/UpdateUserRole`,
        JSON.stringify(data),
        this.getHeaderWithToken());
    } else {
      return this.https.post(`${this.url}user/AddUserRole`,
        JSON.stringify(data),
        this.getHeaderWithToken());
    }
  }

  getAllRoleUserMapping() { // GetAllRoleUserMapping
    return this.https.get(`${this.url}user/GetAllRoleUserMapping`,
    this.getHeaderWithToken());
  }


  unlockUserAcc(data: any) {
    return this.https.post(`${this.url}user/UnlockUserAccount`,
      JSON.stringify(data),
      this.getHeaderWithToken());
  }

  resetUserAccountPassword(data: any) {
    return this.https.post(`${this.url}user/ResetPasswordByAdmin`,
      JSON.stringify(data),
      this.getHeaderWithToken());
  }

/// Role and Module Mapping ///
  getAllControllerNames() { // GetAllControllerNames
    return this.https.get(`${this.url}user/GetAllControllerNames`,
    this.getHeaderWithToken());
  }

  getAllControllerActionNames(data: any) { // GetAllControllerActionNames
    return this.https.post(`${this.url}user/GetAllControllerActionNames`,
      JSON.stringify(data),
      this.getHeaderWithToken());
  }

  getAllRouteNameList() { // GetAllRouteNameList
    return this.https.get(`${this.url}user/GetAllRouteNameList`,
    this.getHeaderWithToken());
  }

  getAllControllerActionRoleMappings(data: any) { // GetAllControllerActionRoleMappings
    return this.https.post(`${this.url}user/GetControllerActionRoleMappingByRoleId`,
      JSON.stringify(data),
      this.getHeaderWithToken());
  }

  addControllerActionRoleMapping(data: any) {
    return this.https.post(`${this.url}user/AddUpdateControllerActionRoleMapping`,
    JSON.stringify(data),
    this.getHeaderWithToken());
  }

  deleteControllerActionRoleMapping(data: any) {// DeleteControllerActionRoleMapping
    return this.https.post(`${this.url}user/DeleteControllerActionRoleMapping`,
    JSON.stringify(data),
    this.getHeaderWithToken());
  }


// Role-Permssion mapping
  getAllUserRoles() { // GetAllUserRoleList
    return this.https.get(`${this.url}user/GetAllUserRoleList`,
    this.getHeaderWithToken());
  }

  getUserRoleAndPermissionMapping(data: any) { // GetUserRoleAndPermissionMappingList
    return this.https.post(`${this.url}user/GetUserRoleAndPermissionMappingList`,
    JSON.stringify(data),
    this.getHeaderWithToken());
  }

  addUpdateUserRoleAndPermissionMapping(data: any) {
    return this.https.post(`${this.url}user/AddUpdateUserRoleAndPermissionMapping`,
    JSON.stringify(data),
    this.getHeaderWithToken());
  }

/// User-Role Mapping
  getAllUser() { // GetAllUserRoleList
    return this.https.get(`${this.url}user/GetAllUserList`,
    this.getHeaderWithToken());
  }

  getUserAndRoleMappingList(data: any) { // GetUserAndRoleMappingList
    return this.https.post(`${this.url}user/GetUserAndRoleMappingList`,
    JSON.stringify(data),
    this.getHeaderWithToken());
  }

  addUpdateUserAndRoleMapping(data: any) {
    return this.https.post(`${this.url}user/AddUpdateUserAndRoleMapping`,
    JSON.stringify(data),
    this.getHeaderWithToken());
  }


/// Shared Function for User management
  getTempPermissions() {
    return{
      Roles: [
        {
          RoleName: 'Site-FullAccess',
          Description: 'Admin for site activity of all tab',
          Id: 'e69d3466-57a3-4ee3-846a-77a12a75ec93',
          IsActive: true,
          Access: ['Customers', 'Funds', 'Data Entry', 'Reports', 'User Management', 'Distributions',
          'Import', 'Export', 'Preprocess', 'Dividend Calendar', 'Import', 'Import-Export'],
        },
        {
          RoleName: 'Transmission Specialist',
          Description: 'Selected tab Access',
          Id: 'e69d3466-57a3-4ee3-846a-77a12a75ec93',
          Access: ['Import', 'Export', 'Import-Export', 'Reports'],
          IsActive: true,
        },
      ],
    };
     // Roles;
  }

  setUserPermissions(data: any) {
    this.roles = data.Roles;
  }

  getUserPermissions() {
    return this.roles;
  }

  getAccessableLinksForRoles() {
    const roles = this.getUserPermissions(); // this.getTempPermissions().Roles;
    let accessableLink = [];

    if (roles && roles.length) {
      roles.forEach((as: any) => {
        as.UniqueAccessModules = as.UniqueAccessModules.map((v: any) => v = v.replace(/\s/g, ''));
        accessableLink = this.menuList.filter((m: any) => {
          // console.log(as.UniqueAccessModules,'-->', m.name.replace(/\s/g,''), as.UniqueAccessModules.includes(m.name.replace(/\s/g,'')) )
          return as.UniqueAccessModules.includes(m.name.replace(/\s/g, ''))
            || (accessableLink && accessableLink.map(al => al.name).includes(m.name));
        }).map((d) => {
          return {path: d.path, name: d.name};
        });
      });
        sessionStorage.setItem('permission', JSON.stringify(accessableLink));
    } else {
      return (sessionStorage.getItem('permission') ? JSON.parse(sessionStorage.getItem('permission')) : []);
    }
    // console.log('links', accessableLink)
    return accessableLink;
  }



}

// this.menuList.map(ml => {
//   console.warn(as.Access.includes(ml.name) && !tempData.includes(ml.name));
//   if (as.Access.includes(ml.name) && !tempData.includes(ml.name)) {
//     tempData.push(ml.name);
//   }
// });