import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  userForm = new FormGroup({});
  loadingIcon: boolean;

  constructor(private shareds: SharedService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.userForm = new FormGroup({
      userEmail: new FormControl('', [Validators.required,  Validators.pattern(/^[a-zA-Z0-9._%+-@]*[a-zA-Z0-9._%+-]+\.[a-z]{2,6}$/)]),
    });
  }

  gotoHome() {
    this.router.navigateByUrl('user-auth');
  }

  forgotPasswordCheck() {
    this.shareds.waitingSign(true);
    // console.log(this.userForm, this.userForm['controls'].userEmail['value']);
    this.authService.forgotPassword({Email: this.userForm['controls'].userEmail['value']})
      .subscribe(res => {
        this.shareds.waitingSign(false);
      if (res['IsSuccess']) {
        this.shareds.toastMsgAlert({ options: 'alert', msg: 'Reset password link sent on your email' });
      } else {
        this.shareds.toastMsgAlert({ options: 'alert', msg: `Provided email address not registered with us`, error: true });
      }
    }, err => {
      console.log(err);
      this.shareds.waitingSign(false);
      this.shareds.toastMsgAlert({ options: 'alert', msg: 'Operation failed', error: true });
    });
  }


}
