import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmationBox } from '../user-dashboard/user.class';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  displayPriceTypeForFF = [2160];
  numberKeyCode = [8, 37, 38, 39, 40, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110];
  confirmationPopup = new Subject<ConfirmationBox>();
  confirmationPopup$ = this.confirmationPopup.asObservable();

  togglebar = new BehaviorSubject(false);

  toastMsg = new BehaviorSubject({});
  toastMsg$ = this.toastMsg.asObservable();

  userProfile = new BehaviorSubject({});
  userProfile$ = this.userProfile.asObservable();

  loadingIcon = new BehaviorSubject(false);

  customerFilters = {
    contacts : new BehaviorSubject({}),
    sourceFunds: new BehaviorSubject({}),
    pendingFunds : new BehaviorSubject({}),
    indiceFunds : new BehaviorSubject({})
  };

  customerContactFilter$ = this.customerFilters.contacts.asObservable();
  customerSourceFundsFilter$ = this.customerFilters.sourceFunds.asObservable();
  customerPendingFundsFilter$ = this.customerFilters.pendingFunds.asObservable();
  customerIndiceFundsFilter$ = this.customerFilters.indiceFunds.asObservable();

  private notifyFundFilter = new BehaviorSubject({});
  notifyFundFilterObservable$ = this.notifyFundFilter.asObservable();

  private menuBarT = new Subject<boolean>();
  menuBarT$ = this.menuBarT.asObservable();

  sisAsOfDate = new Date();

  constructor(private route: Router) { }

  public notifyFilter(data: any) {
    if (data) {
      this.notifyFundFilter.next(data);
    }
  }

  get menuList() {
    return [
      {name: 'Home', path: '/dashboard/home', icon: 'icon_home.png', iconActive: 'icon_home_active.png', active: false, permission: true },
      {
        name: 'User Management', path: '/dashboard/user-management',
        icon: 'user_management.png', iconActive: 'user_management_active.png',
        active: false, permission: false,
      },
      {
        name: 'Customers', path: '/dashboard/customers', icon: 'icon_customer.png', iconActive: 'icon_customer_active.png',
        active: false, permission: false
      },
      {
        name: 'Funds', path: '/dashboard/funds', icon: 'icon_funds.png', iconActive: 'icon_funds_active.png',
        active: false, permission: false
      },
      {
        name: 'Distributions', path: '/dashboard/distributions', icon: 'icon_distributions.png',
        iconActive: 'icon_distributions_active.png',
        active: false, permission: false
      },
      {
        name: 'Data Entry', path: '/dashboard/data-entry', icon: 'icon_data_entry.png', iconActive: 'icon_data_entry_selected.png',
        active: false, permission: false
      },
      {
        name: 'Preprocess', path: '/dashboard/preprocess', icon: 'icon_preprocess.png', iconActive: 'icon_preprocess_selected.png',
        active: false, permission: false
      },
      {
        name: 'Import', path: '/dashboard/import', icon: 'icon_archive.png', iconActive: 'icon_archive_selected.png',
        active: false, permission: false
      },
      {
        name: 'Export', path: '/dashboard/export', icon: 'icon_export.png', iconActive: 'icon_export_active.png',
        active: false, permission: false
      },
      {
        name: 'Dividend Calendar', path: '/dashboard/dividend-calendar', icon: 'icon_calendar.png',
        iconActive: 'icon_calendar_selected.png',
        active: false, permission: false
      },
      {
        name: 'Reports', path: '/dashboard/reports', icon: 'icon_report.png', iconActive: 'icon_report_active.png',
        active: false, permission: false
      },
      {
        name: 'Import-Export', path: '#', icon: 'import_export.png', iconActive: 'import_export_active.png',
        active: false, permission: false,
        submenu: [
          {  name: 'Import-Dashboard', path: '/dashboard/import-process', active: false, permission: false  },
          {  name: 'Export-Dashboard', path: '/dashboard/export-process', active: false, permission: false  },
        ]
      }
    ];
  }

  /// Set User Infomation On Local Storage as object//
  setUserLocal(data: any) {
    localStorage.setItem('authToken', JSON.stringify(data));
  }

  /// GET User Infomation FROM Local Storage //
  getUserLocal() {
    return (localStorage.getItem('authToken') ? localStorage.getItem('authToken') : '');
  } /// JSON.parse(localStorage.getItem('authToken'))

  callConfirmPopup(data: any) {
    this.confirmationPopup.next(data);
  }

  toastMsgAlert(data: any) {
    this.toastMsg.next(data);
  }

  toggleSideBar(value: any) {
    this.togglebar.next(value); // = !this.togglebar;
  }

  shareUserProfile(data: any) {
    this.userProfile.next(data);
  }

  waitingSign(state: boolean) {
    this.loadingIcon.next(state);
  }

  setAsOfDate(date: Date) {
    this.sisAsOfDate = date;
  }

  menuBarToggle(state: boolean) {
    this.menuBarT.next(state);
  }

  getAsOfDate() {
    return this.sisAsOfDate;
  }

  formatdDate(date: Date) {
    const currentDate = new Date(date);
    const month = currentDate.getMonth() + 1;  const day = currentDate.getDate(); const year = currentDate .getFullYear();
    return `${year}/${month}/${day}`;
  }

  dateFormatTo_mmddyyyy(date: Date) {
    const currentDate = new Date(date);
    const month = currentDate.getMonth() + 1;  const day = currentDate.getDate(); const year = currentDate .getFullYear();
    return `${month}/${day}/${year}`;
  }

  transMethodList() {
    return [
      {id: '156', name: 'ABC', parameters: '{ user: , pointers: , exl-list: }' },
      {id: '158', name: 'XYZ', parameters: '{ record: , user: , pdf: }' },
      {id: '160', name: 'PQR', parameters: '{ user: }' },
    ];
  }

  setCustomerContactsFilter(data: any) {
    this.customerFilters.contacts.next(data);
  }

  setCustomerSourceFundsFilter(data: any) {
    this.customerFilters.sourceFunds.next(data);
  }

  setCustomerPendingFundsFilter(data: any) {
    this.customerFilters.pendingFunds.next(data);
  }

  setCustomerIndiceFundsFilter(data: any) {
    this.customerFilters.indiceFunds.next(data);
  }

  clearedCustomerFilterData(seletedTab: string){
    switch (seletedTab) {
      case 'contacts' :
        return  { DataSourceId : -1,  DepartmentId: -1, State: -1,  City: -1  };

      case 'sourceFunds' :
          return {  FundId: -1,  IsDistributionProvided: -1, IsMilProvided: -1,  IsNAVProvided: -1  };

      case 'pendingFunds' :
          return {  FundId: -1  };

      case 'all' :
          return  {
            contacts : {  DataSourceId : -1,  DepartmentId: -1,  State: -1,  City: -1 },
            sourceFunds: {  FundId: -1,  IsDistributionProvided: -1,  IsMilProvided: -1,  IsNAVProvided: -1  },
            pendingFunds : {   FundId: -1  }
          };
    }
  }

  getDummyJobList() {
    return [
      {id: '31', JobName: 'Stasjjsd sj', JobDesc: 'XYZ', JobCat: '9116555', cust_symbole: 'ZZAW',
        service: 'NAV?MIL/DIV', Frequency: 'Once', dollorFund: true, comment: 'ssgggs s', cust: 'view', shareclass: '', intNo: 110
      },
      {id: '291',JobName: 'Pkkhs sj', JobDesc: 'PQEE', JobCat: '9516555', cust_symbole: 'PQAW',
        service: 'DIV', Frequency: 'Quatrrly', dollorFund: true, comment: 'ssgggs s', cust: 'view', shareclass: '', intNo: 210},
      {id: '13',JobName: 'PUsdsh dsj', JobDesc: 'PUU', JobCat: '6416555', cust_symbole: 'PUAW',
        service: 'NAV', Frequency: 'Yearly', dollorFund: true, comment: 'ssgggs s', cust: 'view', shareclass: '', intNo: 310},
      {id: '32', JobName: 'Ntahs ss', JobDesc: 'NHTT', JobCat: '356555', cust_symbole: 'ZZAW',
        service: 'NAV', Frequency: 'Monthly', dollorFund: true, comment: 'ssgggs s', cust: 'view', shareclass: '', intNo: 211},
      {id: '65', JobName: 'Xoosdh s', JobDesc: 'XYZ', JobCat: '3616555', cust_symbole: 'PPA',
        service: 'MIL', Frequency: 'Quatrrly', dollorFund: true, comment: 'ssgggs s', cust: 'view', shareclass: '', intNo: 130},
      {id: '97', JobName: 'Gharsk s', JobDesc: 'GARR', JobCat: '9116555', cust_symbole: 'GAAW',
        service: 'DIV', Frequency: 'Once', dollorFund: true, comment: 'ssgggs s', cust: 'view', shareclass: '', intNo: 410},
      {id: '9', JobName: 'Sooaj', JobDesc: 'AXYZ', JobCat: '75116555', cust_symbole: 'AZAW',
      service: 'MIL', Frequency: 'Monthly', dollorFund: true, comment: 'ssgggs s', cust: 'view', shareclass: '', intNo: 212}
    ];
  }

  getFileFormatsExport() {
    return [
      {name: 'CSV', id: '1', value: 'CSV'},
      {name: 'TXT', id: '2', value: 'TXT'},
      {name: 'EXL', id: '3', value: 'EXL'},
      {name: 'EXL (2003)', id: '4', value: 'EXL_2003'},
    ];
  }

  get FirstReportMonths() {
    return [
      {value: 'Day_In_Jan', display: 'January'}, {value: 'Day_In_Feb', display: 'February'},
      {value: 'Day_In_Mar', display: 'March'}, {value: 'Day_In_Apr', display: 'April'},
      {value: 'Day_In_May', display: 'May'}, {value: 'Day_In_Jun', display: 'June'},
      {value: 'Day_In_Jul', display: 'July'}, {value: 'Day_In_Aug', display: 'August'},
      {value: 'Day_In_Sep', display: 'September'}, {value: 'Day_In_Oct', display: 'October'},
      {value: 'Day_In_Nov', display: 'November'}, {value: 'Day_In_Dec', display: 'December'}
    ];
  }

  // get fidelityReport() {
  //   return [
  //     {value: 'Job Details', display: 'Job Details'},
  //     {value: 'Job History', display: 'Job History'},
  //     {value: 'Job Error Logs', display: 'Job Error Logs'},
  //   ]
  // }
  get timeSpanList () {
    return [
      {label: '30 mins', mins: '30'},
      {label: '1 hr', mins: '60'},
      {label: '1 hr 30 mins', mins: '90'},
      {label: '2 hrs', mins: '120'},
      {label: '2 hrs 30 mins', mins: '150'},
      {label: '3 hrs', mins: '180'},
      {label: '3 hrs 30 mins', mins: '210'},
      {label: '4 hrs', mins: '240'},
      {label: '4 hrs 30 mins', mins: '270'},
      {label: '5 hrs', mins: '300'},
    ]
  }

}

