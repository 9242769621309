import { Component, OnInit } from '@angular/core';
// import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageConstants } from 'src/app/constants/message.constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  loadingIcon: boolean;
  changePassword = {
    new: '', reenter: '',
    view : {new: false, reenter: false}
  };
  token: string;

  constructor(private shareds: SharedService, private authService: AuthService, private router: Router,
      private aRouter: ActivatedRoute, private msgConst: MessageConstants) { }

  ngOnInit() {
    this.aRouter.queryParamMap.subscribe((ev: any) => {
      this.token = '';
      if (ev && ev.params) {
        setTimeout(() => {
          if (ev.params && ev.params.token) {
            this.token = ev.params.token;
            if (this.token) {
              this.verifyToken(ev.params.token);
            }
          } else {
           this.gotoHome();
          }
        }, 200);
      }
    });

    this.changePassword = {
      new: '', reenter: '',
      view : {new: false, reenter: false}
    };
  }

  gotoHome() {
    this.router.navigateByUrl('user-auth');
  }

  gotoFP() {
    this.router.navigateByUrl('user-auth/forgot-password');
  }

  verifyToken(token: string) {
    // console.log('toooken', token);
     this.authService.verifyResetPasswordToken(token).subscribe((result) => {
      if (result['IsSuccess']) {

      } else {
        this.shareds.toastMsgAlert({ options: 'alert',
          msg: 'Token expired, Please re-generate link. Redirecting to forget password.', error: true });
        this.gotoFP();
      }
     }, (err) => {
       // console.log('errrrrrrrrrrrrrrrrrrr', err)
      this.shareds.toastMsgAlert({ options: 'alert',
        msg: 'Token expired, Please re-generate link. Redirecting to forget password.', error: true });
      this.gotoFP();
     });
  }

  resetPassword() {
    this.shareds.waitingSign(true);
    const params = {
      NewPassword: this.changePassword.new,
      ConfirmPassword: this.changePassword.reenter
    };
    // console.log(params);
    this.authService.resetPassword(params, this.token)
      .subscribe(res => {
        this.shareds.waitingSign(false);
        if (res['IsSuccess']) {
          this.shareds.toastMsgAlert({ options: 'alert', msg: `Reset password successful. Please Login with new password.` });
          this.gotoHome();
        }
    }, err => {
      console.log(err);
      this.shareds.waitingSign(false);
      this.shareds.toastMsgAlert({ options: 'alert', msg: err['error']['error_description'], error: true });
    });
  }

}
