import { Injectable } from '@angular/core';

@Injectable()
export class MessageConstants {

    msg = {
        noData: 'No records found.',
        noCustomer: 'Select customer to load data.',
        noFund: 'Select fund family to load data.',
        loading: 'Loading Data',
        selectTab: 'Select tab to view more information',
        selectFund: 'Select fund(s) to load data.',

        add: 'added successfully.',
        update: 'updated successfully.',
        delete: 'deleted successfully.',
        failed: 'operation failed',
        insert: 'inserted successfully',

        linked: 'linked successfully',
        unlinked: 'unlinked successfully',
        hoursStartEndError: 'Start Hours cannot be less than End Hour(s)',
        doesNotExist: 'No records with ',
        moveToSF: 'moved to sourced fund successfully',
        moveToJF: 'moved to junk fund successfully',
        moveToPF: 'moved to pending fund successfully',
        moveToIF: 'moved to indice fund successfully',
        moveFailed: 'Moving funds operation unsuccessful',
        invalidEmail: 'Please provide a valid email address',
        invalidDomain: 'Please provide a valid domain address with @ and .com',
        presuccess: 'Pre-process inserted successfully',
        preprocessNoData: 'No fund(s) available for  pre-process ',
        subscribe_service: 'Subscribe aleast one service',
        processOnGoing: 'Process is already running',
        toGreaterThanFrom: 'To date greater than From Date',
        over30dys: 'Maximum 30 days range is allowed',
        assignFF: 'assign fund-family to update',
        onlycsv: 'Only CSV or Excel is accepted',
        noSourceProv: 'Select source provider',
        newSourceP: 'Source Provider',
        invalidReportForm: 'Please fill all the required (* marked) field(s)',
        reportError: 'Not able to download reports',
        reportSuccess: 'Downloading reports, Please wait!'
    };

    type = {
        contact: 'Contact(s)',
        customer: 'Customer(s)',
        fund: 'Fund Family(s)',
        sourceFund: 'Source Fund(s)',
        pendingFund: 'Pending Fund(s)',
        junkFund: 'Junk Fund(s)',
        indiceFund: 'Indice Fund(s)',
        lSourceFund: 'Linked Source Fund(s)',
        lPedningFund: 'Linked Pending Fund(s)',
        lIndiceFund: 'Linked Indice Fund(s)',
        prep: 'Pre-process(s)',
        divCal: 'Dividend Calendar',
        exportJob: 'Export Job',
        configkey: 'Configration Keys',
        trasnMethod: 'Transmission Method',
        schedule: 'Schedule',
        depJob: 'Dependent Job',
        profile: 'Profile details'
    };

    distMsg = {
        noFund: 'Select Fund Family',
        success: 'information saved successfully.',
        clearRow: 'Are you sure you want to clear selected row(s)? Click "Yes" to clear or "Cancel" to discard',
        reqFieldNotValid: 'Operation failed',
        invalidField: 'Highlighted field(s) are mandatory or not required',
        reqFields: 'Highlighted field(s) are mandatory',
        field_req: 'Atleast 1 field is requried other than date(s)',
        initial: 'Initial',
        verifier: 'Verifier',
        unverified: 'Distribution set to unverified',
        dist: 'Distribution',
        novalues: 'Please fill fields to save distribtion(s)',
        noRowEdited: 'No row(s) edited',
        fSorted: 'Fund Sorted successfully'
    };

    dataEntryMsg = {
        addNav: 'Changes Successfully Saved! ',
      addNavError: 'Failed To Save Changes!',
      addEntryError: 'Failed To Save Changes due to invalid CUSIP!',
      ineligibleError: 'Fund not available for Equity Pricing',
        duplicateError: 'Data already exists for this cusip for selected date!',
        dcDateAlert: `Select date greater than today's date`,
        dcXonCall: `Ex Date cannot be same as Call Date`,
        dcDivCallMatch: `(Call Date Cap Gain) or (No Ex Date) cannot be same as Call Date Dividend`,
        dcCGCallMatch: `(Call Date Dividend) or (No Ex Date) cannot be same as Call Date Cap Gain`,
        dcXMatch: `(Call Date Cap Gain) or (Call Date Dividend) cannot be same as No Ex Date`,
        multiNoDate: `No checkbox's checked, Atleast 1 is required`,
        multiDateMsg: `The date you are selecting contain multiple type values, please select the type you wish to delete`
    };


    constructor() { }

// tslint:disable-next-line: eofline
}
