import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { ConfigService } from './ConfigService';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  url = '';//environment.getBaseUrl();

  proprocessRunningBS = new BehaviorSubject<boolean>(false);
  preprocessObv = new Subject<any>();

  constructor(private https: HttpClient, private configService: ConfigService) { 
    //console.log('config', configService.config);
    this.url = configService.config.apiUrl;
    this.setProcessLoader(false);
   }

  getHeaderWithToken(): any {
    return {
      headers: new HttpHeaders({  /// returns headers with token //
        'Content-Type': 'application/json',
        Authorization:  localStorage.getItem('authToken') ? 'Bearer ' + localStorage.getItem('authToken') : ''
      })
    };
  }

  getHeader() {
    return {  /// returns only headers
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }
 
  checkFundFamilyMapping(params: any) {
    return this.https.post(`${this.url}Common/CheckFundFamilyMapping`, JSON.stringify(params), this.getHeaderWithToken());
  }
  checkIndiceFundFamilyMapping(params: any) {
    return this.https.post(`${this.url}Common/checkIndiceFundFamilyMapping`, JSON.stringify(params), this.getHeaderWithToken());
  }

  getImportPriceTypes() {
    return this.https.get(`${this.url}Common/GetImportPriceTypes`, this.getHeaderWithToken());
  }

  getFundFamilyBySearchText(data: any) {
    return this.https.post(`${this.url}Common/GetFundFamilyBySearchText`, JSON.stringify(data), this.getHeaderWithToken());
  }

  getCountryList() {
    return this.https.get(`${this.url}Common/GetCountryList`, this.getHeaderWithToken());
  }

  getStateListByCountryId(id: any) {
    return this.https.post(`${this.url}Common/GetStateListByCountryId`, id, this.getHeaderWithToken());
  }

  getStateList() {
    return this.https.get(`${this.url}Common/GetStateList`, this.getHeaderWithToken());
  }

  getCityListByStateId(id: any) {
    return this.https.post(`${this.url}Common/GetCityListByStateId`, id,  this.getHeaderWithToken());
  }

  getFundFamilyList() {
    return this.https.get(`${this.url}Common/GetFundFamilyList`,  this.getHeaderWithToken());
  }

  getFundFamilyListByCustomerId(params: any) {
    return this.https.post(`${this.url}Common/GetFundFamilyListByCustomerId`, JSON.stringify(params), this.getHeaderWithToken());
  }

  getExclusiveFundFamilyListByCustomerId(params: any) {
    return this.https.post(`${this.url}Common/GetExclusiveFundFamilyListByCustomerId`, JSON.stringify(params), this.getHeaderWithToken());
  }

  getMilRateOffsetTypeList() {
    return this.https.get(`${this.url}Common/GetMilRateOffsetTypeList`,  this.getHeaderWithToken());
  }

  getNAVOffsetTypeList() {
    return this.https.get(`${this.url}Common/GetNAVOffsetTypeList`,  this.getHeaderWithToken());
  }

  getMonthEndRateBehaviorList() {
    return this.https.get(`${this.url}Common/GetMonthEndRateBehaviorList`,  this.getHeaderWithToken());
  }

  getMonthEndRateDirectionList() {
    return this.https.get(`${this.url}Common/GetMonthEndRateDirectionList`,  this.getHeaderWithToken());
  }

  getMonthEndRateTypeList() {
    return this.https.get(`${this.url}Common/GetMonthEndRateTypeList`,  this.getHeaderWithToken());
  }

  getMultiRateDirectionList() {
    return this.https.get(`${this.url}Common/GetMultiRateDirectionList`,  this.getHeaderWithToken());
  }

  getMultiRateTypeList() {
    return this.https.get(`${this.url}Common/GetMultiRateTypeList`,  this.getHeaderWithToken());
  }

  getOmnibusTypeListByFamilyId(data: any) {
    return this.https.post(`${this.url}Common/GetOmnibusTypeListByFamilyId`, JSON.stringify(data), this.getHeaderWithToken());
  }

  getShareClassList(shareClassFundFlag: any) {
    return this.https.post(`${this.url}Common/GetShareClassList`, shareClassFundFlag,  this.getHeaderWithToken());
  }

  getWeekendTypeList() {
    return this.https.get(`${this.url}Common/GetWeekendTypeList`,  this.getHeaderWithToken());
  }

  getPositionList() {
    return this.https.get(`${this.url}Common/GetPositionList`,  this.getHeaderWithToken());
  }

  getDataSourceList() {
    return this.https.get(`${this.url}Common/GetDataSourceList`,  this.getHeaderWithToken());
  }

  getDayTypeList() {
    return this.https.get(`${this.url}Common/GetDayTypeList`,  this.getHeaderWithToken());
  }

  getDepartmentList() {
    return this.https.get(`${this.url}Common/GetDepartmentList`,  this.getHeaderWithToken());
  }

  getLocationTypeList() {
    return this.https.get(`${this.url}Common/GetLocationTypeList`,  this.getHeaderWithToken());
  }

  getPhoneNumberTypeList() {
    return this.https.get(`${this.url}Common/GetPhoneNumberTypeList`,  this.getHeaderWithToken());
  }

  getReasonList() {
    return this.https.get(`${this.url}Common/GetReasonList`,  this.getHeaderWithToken());
  }

  getSourceProviderList() {
    return this.https.get(`${this.url}Common/GetSourceProviderList`,  this.getHeaderWithToken());
  }

  getSecurityTypesList() {
    return this.https.get(`${this.url}Common/GetSecurityTypesList`, this.getHeaderWithToken());
  }

  getMillWeekendTypeList() {
    return this.https.get(`${this.url}Common/GetMillWeekendTypeList`, this.getHeaderWithToken());
  }


  getDividendFrequencyList() {
    return this.https.get(`${this.url}Common/GetDividendFrequencyList`,  this.getHeaderWithToken());
  }

  getPricingSourceList() {
    return this.https.get(`${this.url}Common/GetPricingSourceList`,  this.getHeaderWithToken());
  }

  getStatusList() {
    return this.https.get(`${this.url}Common/GetStatusList`,  this.getHeaderWithToken());
  }

  /// Reports API collection //
  getAllReportList() {
    return this.https.get(`${this.url}Report/GetAllReports`,  this.getHeaderWithToken());
  }

  postReport_Download(data: any) {
    return this.https.post(`${this.url}Report/DownloadReport`, JSON.stringify(data), this.getHeaderWithToken());
  }

  /// Pre-Process // /PreProcess/InsertPreNavsByFamilyIdList
  savePreProcessData(data: any) {
    return this.https.post(`${this.url}PreProcess/InsertPreNavsByFamilyIdList`, JSON.stringify(data), this.getHeaderWithToken());
  }

  isSelectedDateHoliday(data: any) {
    return this.https.post(`${this.url}Common/GetAllHolidays`, JSON.stringify(data), this.getHeaderWithToken());
  }

//// Pre Process Functions ///

  getHolidayList(data: any) {
    return this.https.post(`${this.url}Common/GetAllHolidays`, JSON.stringify(data), this.getHeaderWithToken());
  }

  checkIsProcessInProgress(data: any) {
    return this.https.post(`${this.url}PreProcess/IsPreProcessInProgress`, JSON.stringify(data), this.getHeaderWithToken());
  }

  runPreProcessFor_Nav_Mil(data: any) {
    return this.https.post(`${this.url}PreProcess/PreProcessNavAndMillRate`, JSON.stringify(data), this.getHeaderWithToken());
  }

  processStatus(data: any) {
    this.preprocessObv.next(data);
  }

  setLoaderTrue() {
    this.proprocessRunningBS.next(true);
  }

  setProcessLoader(value: boolean) {
    this.proprocessRunningBS.next(value);
  }

  startProcess(data: any, type: string) {
   // this.proprocessRunningBS.next(true);
      this.runPreProcessFor_Nav_Mil(data).subscribe((result: any) => {
        if (result['IsSuccess']) {
          this.setProcessLoader(false);
          this.processStatus({options: 'preprocess', status: 'success', response: result });
        } else {
          // console.log('success false', result);
          this.processStatus({options: 'preprocess', status: 'success', response: result });
          this.setProcessLoader(false);
        }
      }, (err: any) => {
        this.setProcessLoader(false);
        this.processStatus({options: 'preprocess', status: 'error', response: '' });
      });
  }


// tslint:disable-next-line: eofline
}
