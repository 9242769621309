import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigService } from './ConfigService';

@Injectable({
  providedIn: 'root'
})
export class ConfigrationsService {

  url = '';//environment.getBaseUrl();
  
  constructor(private https: HttpClient, private configService: ConfigService) { 
    //console.log('config', configService.config);
    this.url = configService.config.apiUrl;
  }

  getHeaderWithToken(): any {
    return {
      headers: new HttpHeaders({  /// returns headers with token //
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('authToken') ? 'Bearer ' + localStorage.getItem('authToken') : ''
      })
    };
  }

  getHeaderWithTokenMultipart(): any {
    return {
      headers: new HttpHeaders({  /// returns headers with token //
        // 'Content-Type': 'multipart/form-data',
        Authorization:  localStorage.getItem('authToken') ? 'Bearer ' + localStorage.getItem('authToken') : ''
      })
    };
  }

  getHeader() { /// returns only headers
    return {  headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  }

  getaAllSourceProviders() {
    return this.https.get(`${this.url}ConfigureImport/GetAllSourceProviders`, this.getHeaderWithToken());
  }

  getaAllFileType() {
    return this.https.get(`${this.url}ConfigureImport/GetAllFileTransferType`, this.getHeaderWithToken());
  }

  getaAllRowType() {
    return this.https.get(`${this.url}ConfigureImport/GetAllRowType`, this.getHeaderWithToken());
  }

  getaAllImportColFormat() {
    return this.https.get(`${this.url}ConfigureImport/GetAllImportColumnFormat`, this.getHeaderWithToken());
  }

  getaAllImportColValidationType() {
    return this.https.get(`${this.url}ConfigureImport/GetImportColumnValidationTypes`, this.getHeaderWithToken());
  }

  getProviderDetailById(data: any) {
    return this.https.post(`${this.url}ConfigureImport/GetSourceProviderDetailsByID`, JSON.stringify(data), this.getHeaderWithToken());
  }

  /// Get File Types for dropdown in basic tab//
  getFileType() {
    return this.https.get(`${this.url}ConfigureImport/GetImportFileType`, this.getHeaderWithToken());
  }

  /// Get File Import Type dropdown list for Basic Tab //
  getImportTypes() {
    return this.https.get(`${this.url}ConfigureImport/GetImportTypes`, this.getHeaderWithToken());
  }

  getFormatByTypeId(data: any) {
    return this.https.post(`${this.url}ConfigureImport/GetImportMasterColumnsByTypeID`, JSON.stringify(data), this.getHeaderWithToken());
  }

  saveImportBasicInfo(data: any) {
    return this.https.post(`${this.url}ConfigureImport/SaveSourceProvider`, JSON.stringify(data), this.getHeaderWithToken());
  }

  saveImport_File_Email(data: any) {
    return this.https.post(`${this.url}ConfigureImport/SaveImportFiles`, JSON.stringify(data), this.getHeaderWithToken());
  }

  saveFileParser(data: any) {
    return this.https.post(`${this.url}ConfigureImport/SaveImportFileParser`, JSON.stringify(data), this.getHeaderWithToken());
  }

  saveImportColumnInfo(data: any) {
    return this.https.post(`${this.url}ConfigureImport/SaveImportColumns`, JSON.stringify(data), this.getHeaderWithToken());
  }

  /// Import Dashboard API //

  getImportDashboardData(data: any) {
    return this.https.post(`${this.url}ConfigureImport/GetImportRequestList`, JSON.stringify(data), this.getHeaderWithToken());
  }

  getImportUpcomingFilesList(data: any) {
    return this.https.post(`${this.url}ConfigureImport/GetImportRequestListForUpcomingFiles`, JSON.stringify(data), this.getHeaderWithToken());
  }

  getSPImportDetails() {
    return this.https.get(`${this.url}ConfigureImport/GetAllSourceProviderImportDetails`, this.getHeaderWithToken());
  }

  uploadManualImportFile(data: any) {
    return this.https.post(`${this.url}ConfigureImport/UploadImportFile`, data, this.getHeaderWithTokenMultipart());
  }

///// ?????????????????????? Export Services ???????????????????? ////////////

  getJobList(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/GetCustomerJobListByCustomerId`, JSON.stringify(data),
    this.getHeaderWithToken());
  }

  getJobsCategoryList() {
    return this.https.get(`${this.url}ExportConfiguration/GetJobCategoryList`, this.getHeaderWithToken());
  }

  getJobServiceTypeList(cId: string) {
    return this.https.get(`${this.url}ExportConfiguration/GetServiceTypeList?CustomerId=${cId}`, this.getHeaderWithToken());
  }

  getJobFormatList(type: boolean) {
    return this.https.get(`${this.url}ExportConfiguration/GetExportFormatList?HasInlineCode=${type}`, this.getHeaderWithToken());
  }

  /// Job Config BASIC ///
  getJobDetailsById(cjId: string) {
    return this.https.get(`${this.url}ExportConfiguration/GetCustomerJobDetailsById?CustomerJobId=${cjId}`, this.getHeaderWithToken());
  }

  addEditCustomerJob(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/AddUpdateCustomerJobDetails`, JSON.stringify(data), this.getHeaderWithToken());
  }

  getExportFileFormats() {
    return this.https.get(`${this.url}ExportConfiguration/GetExportFileFormatList`, this.getHeaderWithToken());
  }

  getDelimiters() {
    return this.https.get(`${this.url}ExportConfiguration/GetDelimiterList`, this.getHeaderWithToken());
  }

  /// Job Config Key //
  getJobConfigKeys(id: any) {
    return this.https.get(`${this.url}ExportConfiguration/GetCustomerExportConfigurationKeyList?CustomerJobID=${id}`,
      this.getHeaderWithToken());
  }

  getConfigKeys() {
    return this.https.get(`${this.url}ExportConfiguration/GetExportConfigurationKeyList`,
      this.getHeaderWithToken());
  }

  saveJobConfigKeys(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/AddUpdateCustomerExportConfigurationKey`, JSON.stringify(data),
      this.getHeaderWithToken());
  }

  deleteJobConfigKey(id: string) {
    return this.https.get(`${this.url}ExportConfiguration/DeleteCustomerExportConfigurationKey?CustomerExportConfigurationKeyID=${id}`,
    this.getHeaderWithToken());
  }

  /// Transmission Method //
  getJobTransmissionMethod(data: any) {
    return this.https.get(
      `${this.url}ExportConfiguration/GetCustomerJobTransmissionMethodList?` +
        `CustomerJobID=${data.id}`, // ${(data.search ? data.search : '')
      this.getHeaderWithToken());
  }

  getTransmissionMethodList() {
    return this.https.get(`${this.url}ExportConfiguration/GetTransmissionMethodList`, this.getHeaderWithToken());
  }

  // getTransmissionMethodTemplate(id: any) {
  //   return this.https.get(`${this.url}ExportConfiguration/GetTransmissionMethodParameterTemplate?` +
  //     `TransmissionMethodI=${id}`, this.getHeaderWithToken());
  // }

  saveTransmissionMethods(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/AddUpdateCustomerJobTransmissionMethod`, JSON.stringify(data),
      this.getHeaderWithToken());
  }

  deleteTransmissionMethod(id: string) {
    return this.https.get(`${this.url}ExportConfiguration/DeleteCustomerJobTransmissionMethod?CustomerJobTransmissionMethodMappingID=${id}`,
    this.getHeaderWithToken());
  }

  /// Job Schedules Method //
  getJobSchedules(id: any) {
    return this.https.get(`${this.url}ExportConfiguration/GetCustomerJobScheduleList?CustomerJobID=${id}`,
      this.getHeaderWithToken());
  }

  saveJobSchedule(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/AddUpdateCustomerJobSchedule`, JSON.stringify(data),
      this.getHeaderWithToken());
  }

  deleteJobSchedule(id: string) {
    return this.https.get(`${this.url}ExportConfiguration/DeleteCustomerJobSchedule?CustomerJobScheduledID=${id}`,
      this.getHeaderWithToken());
  }

  getHolidayScheduleList() { // GeHolidayScheduleList
    return this.https.get(`${this.url}ExportConfiguration/GeHolidayScheduleList`,
      this.getHeaderWithToken());
  }

  getHolidayDateList() { // GetHolidayDateList
    return this.https.get(`${this.url}ExportConfiguration/GetHolidayDateList`,
      this.getHeaderWithToken());
  }

/// Dependent Job Methods ///
  getDependentJobs(id: string) {
    return this.https.get(`${this.url}ExportConfiguration/GetCustomerDependentJobList?CustomerJobID=${id}`,
      this.getHeaderWithToken());
  }

  saveCustomerDependentJobs(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/AddCustomerDependentJobs`, JSON.stringify(data),
      this.getHeaderWithToken());
  }


/// Column //
  getCustomerDefineColumn(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/GetCustomerDefinedColumnList`, JSON.stringify(data), this.getHeaderWithToken());
  }

  getColumnDetails(id: any) {
    return this.https.get(`${this.url}ExportConfiguration/GetExportSQLQueryBuilderDetails?CustomerJobId=${id}`,
      this.getHeaderWithToken());
  }

  getMasterTableList() {
    return this.https.get(`${this.url}ExportConfiguration/GetExportMasterTableList`,
    this.getHeaderWithToken());
  }

  getMasterColumnList(data: any) {
    return this.https.get(`${this.url}ExportConfiguration/GetExportMasterTableDetails?MasterTableId=${data}`,
      this.getHeaderWithToken());
  }

  getExportUnionGroupList() {
    return this.https.get(`${this.url}ExportConfiguration/GetExportUnionGroupList`,
      this.getHeaderWithToken());
  }

  saveColumnInformaion(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/AddUpdateExportSQLQueryBuilderDetails`,
      JSON.stringify(data),
      this.getHeaderWithToken());
  }

  copySQLQueryFromOtherJob(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/CopyExportSQLQueryFromOldJob`,
      JSON.stringify(data),
      this.getHeaderWithToken());
  }

  ///// Job Log API
  getJobLogList(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/GetCustomerJobList`, JSON.stringify(data), this.getHeaderWithToken());
  }

  ///// Customer Job API
  getCustomerJob() {
    return this.https.get(`${this.url}ExportConfiguration/GetCustomerJobCodeList`, this.getHeaderWithToken());
  }

  //// Detail Log API CustomerJobId: string, CDate, JobSchedulingId
  getDetailLog(data: any) {
    return this.https.get(`${this.url}ExportConfiguration/GetCustomerJobLogMessages` +
    `?Date=${data.Date}&CustomerJobId=${data.CustomerJobId}&CustomerJobSchedulingId=${data.CustomerJobSchedulingId}`,
    this.getHeaderWithToken());
  }

  // ReRun Job Process //
  rerunExportJob(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/TriggerExportProcess`, JSON.stringify(data), this.getHeaderWithToken());
  }

  // Cancel Job Process / Schedule //
  cancelScheduleJob(data: any) {
    return this.https.post(`${this.url}ExportConfiguration/CancelScheduledCustomerJobs`, JSON.stringify(data), this.getHeaderWithToken());
  }


}
