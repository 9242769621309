import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigService } from './ConfigService';

@Injectable({
  providedIn: 'root'
})
export class DistributionService {

  url = '';//environment.getBaseUrl();

  constructor(private http: HttpClient, private configService: ConfigService) { 
    //console.log('config', configService.config);
    this.url = configService.config.apiUrl;
  }

  getHeaderWithToken(): any {
    return {
      headers: new HttpHeaders({  /// returns headers with token //
        'Content-Type': 'application/json',
        Authorization:  localStorage.getItem('authToken') ? 'Bearer ' + localStorage.getItem('authToken') : ''
      })
    };
  }

  getHeader() {
    return {  /// returns only headers
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }

  getFundsByFFId(params: any) {
    return this.http.post(`${this.url}DividendEntry/GetAllFundsDescriptionByFundFamilyId`, JSON.stringify(params),
      this.getHeaderWithToken());
  }

  /// History List
  getDistributionHistory(params) {
    return this.http.post(`${this.url}DividendEntry/GetFundDistributionHistory`, JSON.stringify(params), this.getHeaderWithToken());
  }

  getInitialDistributionData(params: any) {
    return this.http.post(`${this.url}DividendEntry/GetInitialDistributionListByFundFamilyId`, JSON.stringify(params),
      this.getHeaderWithToken());
  }


  saveInitailDistribution(params: any) {
    return this.http.post(`${this.url}DividendEntry/UpdateInitialDistributionValues`, JSON.stringify(params), this.getHeaderWithToken());
  }

  deleteInitailValues(params: string) {
    return this.http.post(`${this.url}DividendEntry/DeleteInitialDistributionDataByFundId`, JSON.stringify(params), this.getHeaderWithToken());
  }

  /// Get Verifier List//
  getVerifiedDistributionData(params: any) {
    return this.http.post(`${this.url}DividendEntry/GetVerifyDistributionListByFundFamilyId `, JSON.stringify(params),
      this.getHeaderWithToken());
  }


  /// Get Both List data in 1 function//
  getDistributionDataByType(params: any, type: string) {
    return (
      type === 'initial' ?
      this.http.post(`${this.url}DividendEntry/GetInitialDistributionListByFundFamilyId`, JSON.stringify(params),
        this.getHeaderWithToken()) :  this.http.post(`${this.url}DividendEntry/GetVerifyDistributionListByFundFamilyId `,
          JSON.stringify(params), this.getHeaderWithToken())
    );
  }


  saveDistributionVerfier(params: any) {
    return this.http.post(`${this.url}DividendEntry/UpdateVerifyDistributionValues`, JSON.stringify(params), this.getHeaderWithToken());
  }

  getVerifyDistributionData(params: any) {
    return this.http.post(`${this.url}DividendEntry/GetVerifyDistributionDataByFundId`, JSON.stringify(params), this.getHeaderWithToken());
  }

  getAllDistributionDataByDate(params: any) {
    return this.http.post(`${this.url}DividendEntry/GetAllDistributionDataByFundIdAndEntryDate`, JSON.stringify(params),
    this.getHeaderWithToken());
  }

  unverifyDistribution(id: string) {
    return this.http.post(`${this.url}DividendEntry/UnverifyPastDistribution`, JSON.stringify(id), this.getHeaderWithToken());
  }

  deleteDistribution(id: string) {
    return this.http.post(`${this.url}DividendEntry/DeletePastDistribution`, JSON.stringify(id), this.getHeaderWithToken());
  }


}
