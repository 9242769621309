import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

/// Services
import { AuthService } from './services/auth.service';
import { CustomerService } from './services/customer.service';
import { FundService } from './services/fund.service';
import { CommonService } from './services/common.service';
import { FundConstants } from './constants/fund.constants';
import { UtilitiesService } from './services/utilities.service';
import { MessageConstants } from './constants/message.constants';
import { DistributionService } from './services/distribution.service';


/// PrimeNG Libraries //
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';


import { InfiniteScrollModule } from 'ngx-infinite-scroll';

/// Components
import { AppComponent } from './app.component';
import { UserAuthenticationComponent } from './user-authentication/user-authentication.component';
import { UserLoginComponent } from './user-authentication/user-login/user-login.component';
import { ConfigrationsService } from './services/configrations.service';
import { ForgotPasswordComponent } from './user-authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './user-authentication/reset-password/reset-password.component';
import { ConfigService } from './services/ConfigService';
import { APP_BASE_HREF } from '@angular/common';

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig();
};

@NgModule({
  declarations: [
    AppComponent,
    UserAuthenticationComponent,
    UserLoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TableModule,
    CheckboxModule,
    HttpClientModule,
    InfiniteScrollModule,
    ToastModule,
  ],
  exports: [ TableModule, CheckboxModule, ToastModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    { provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' },
    AuthService,
    CustomerService,
    FundService,
    CommonService,
    FundConstants,
    UtilitiesService,
    MessageConstants,
    MessageService,
    DistributionService,
    ConfigrationsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
