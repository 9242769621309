import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserManagementService } from 'src/app/services/user-management.service';


@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  userForm = new FormGroup({});
  loadingIcon: boolean;
  showPassword = false;

  constructor(private routing: Router, private authService: AuthService, private sharedService: SharedService,
      private formB: FormBuilder, private userService: UserManagementService) { }

  ngOnInit() {
    this.userForm = new FormGroup({
      username: new FormControl('', [Validators.required,  Validators.minLength(3)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });

    this.sharedService.shareUserProfile({});
    this.userService.setUserPermissions({Roles: []});
    // clear auth token on login page visite//
    if (localStorage.getItem('authToken')) {
      localStorage.setItem('authToken', '');
    }
  }

  // check white space at initial
  noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  gotoDash() {
    this.authService.isLoggedInSet(true);
    this.routing.navigateByUrl('dashboard/home');
    //  this.sharedService.waitingSign(true);
  }

  loginUser() {
    this.sharedService.waitingSign(true);
    this.loadingIcon = true;
    this.authService.authenticateUser(this.userForm.value.username, this.userForm.value.password).subscribe((result: any) => {
      if (result && result['access_token']) {
        // console.log(result);
        localStorage.setItem('authToken', result['access_token']);
        this.authService.isLoggedInSet(true);
        this.routing.navigateByUrl('dashboard/home'); // this.routing.navigateByUrl('dashboard/customers');
        setTimeout(() => {
          this.sharedService.waitingSign(false);
          this.loadingIcon = false;
        }, 1000);
      }
    }, (err: HttpErrorResponse) => {
      console.warn(err);
      if (err['error'] && err['error']['error_description']) {
        this.sharedService.toastMsgAlert({ options: 'alert', msg: err['error']['error_description'], error: true });
      }
      this.sharedService.waitingSign(false);
      this.loadingIcon = false;
    });
  }

}
