import { Component, OnInit } from '@angular/core';
import { SharedService } from './services/shared.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'QUODD';

  loader: Observable<boolean>;
  errorAlert: boolean;
  warningAlert: boolean;

  constructor(private sharedService: SharedService, private router: Router, private authService: AuthService,
    private toastMsgService: MessageService) {}

  ngOnInit() {
    this.loader = this.sharedService.loadingIcon.asObservable();

    if (localStorage.getItem('authToken')) {
      // console.log(localStorage.getItem('authToken'));
      this.authService.isLoggedInSet(true);
    } else {
      this.authService.isLoggedInSet(false);
      //this.router.navigate(['user-auth']);
    }

    this.sharedService.toastMsg$.subscribe((res: any) => {
      if (res.hasOwnProperty('options') && res.options === 'alert') {
        this.warningAlert = this.errorAlert = false;
        this.showToast(res);
      }
    });

  }


  // Toast Msgs //
  showToast(obj: any) {
    if (obj && obj.error) {
      this.errorAlert = true;
    }
    if (obj && obj.warning) {
      this.warningAlert = true;
    }
    this.toastMsgService.add({severity: 'info',  detail: obj.msg, life: 3000 });
  }

  hideToast() {
    this.toastMsgService.clear();
  }


}
